.react-phone-input {
    height: 50px !important;
    width: 100% !important;
    border: none !important;
    border-radius: 6px !important;
    font-size: 16px !important;
}

.react-phone-button {
    border: none !important;
    background: unset !important;
}

.react-phone-button .selected-flag {
    background: unset !important;
}

.react-phone-button .country-list .flag {
    top: 6px !important;
}

.react-phone-dropdown {
    box-shadow: none !important;
    margin-left: 0 !important;
    border-radius: 6px !important;
    color: black;
    font-weight: 400;
    width: calc(100vw - 40px) !important;
    max-width: 460px !important;
}
