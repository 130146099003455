$primaryGreen: #00de49;
$secondaryGreen: #009330;
$tertiaryGreen: #4de77f;
$primaryGray: #909a9c;
$secondaryGray: #a6adb0;
$primaryBlue: #0079f5;
$primaryYellow: #f1cb00;
$primaryRed: #ff154e;
$lightTeal: #f8fbfc;
$mediumTeal: #e0eef2;

.sendbird-conversation {
    border: none !important;
}

.sendbird-icon {
    svg {
        display: block;
    }
}

.sendbird-chat-header {
    display: none;
}

.sendbird-conversation__footer {
    padding: 0;

    .sendbird-conversation__typing-indicator {
        left: 15px;
    }
}

// Color overrides

.sendbird-theme--light .sendbird-channel-preview--active {
    border-left: solid 4px $primaryBlue;
}

.sendbird-theme--light
    .sendbird-channel-preview--active
    .sendbird-channel-preview__content__upper__header__channel-name {
    color: $primaryBlue;
}

.sendbird-theme--light .sendbird-color--primary [class*="fill"] {
    fill: $primaryBlue;
}

.sendbird-theme--light .sendbird-badge {
    background-color: $primaryBlue;
}

.sendbird-theme--light .sendbird-label--color-primary {
    color: $primaryBlue;
}

.sendbird-iconbutton {
    color: $primaryBlue;
}

.sendbird-theme--light .sendbird-iconbutton:focus {
    box-shadow: $primaryBlue 0px 0px 0px 2px;
}

.sendbird-theme--light .sendbird-button--primary {
    border: 1px solid $primaryBlue;
    background-color: $primaryBlue;
}

.sendbird-theme--light .sendbird-button--primary:hover {
    background-color: $primaryBlue;
    border-color: $primaryBlue;
}

.sendbird-theme--light .sendbird-button--primary:focus {
    box-shadow: 0px 0px 0px 2px $primaryBlue;
}

.sendbird-theme--light .sendbird-button--primary:active {
    background-color: $primaryBlue;
}

.sendbird-theme--light
    .sendbird-button--primary:focus
    .sendbird-button--primary:hover {
    box-shadow: 0px 0px 0px 2px $primaryBlue;
}

.sendbird-theme--light
    .sendbird-button--primary:focus
    .sendbird-button--primary:active {
    box-shadow: 0px 0px 0px 2px $primaryBlue;
}

.sendbird-theme--light .sendbird-button--secondary:hover {
    border: 1px solid $primaryGray;
}

.sendbird-theme--light
    .sendbird-button--secondary:hover
    .sendbird-button__text {
    color: black;
}

.sendbird-theme--light
    .sendbird-button--secondary:hover
    .sendbird-button--secondary:focus
    .sendbird-button__text {
    color: black;
}

.sendbird-theme--light .sendbird-button--secondary:focus {
    box-shadow: $primaryBlue 0px 0px 0px 2px;
}

.sendbird-theme--light .sendbird-button--secondary:active {
    border: 1px solid $primaryBlue;
}

.sendbird-theme--light
    .sendbird-button--secondary:active
    .sendbird-button__text {
    color: $primaryBlue;
}

.sendbird-theme--light .sendbird-muted-avatar .sendbird-muted-avatar__bg {
    background-color: $primaryBlue;
}

.sendbird-theme--light
    .sendbird-checkbox
    input:focus
    ~ .sendbird-checkbox--checkmark {
    box-shadow: 0px 0px 0px 2px $primaryBlue;
}

.sendbird-theme--light
    .sendbird-checkbox
    input:checked
    ~ .sendbird-checkbox--checkmark {
    background-color: $primaryBlue;
    border: 2px solid $primaryBlue;
}

.sendbird-theme--light .sendbird-reaction-button--selected {
    border: solid 1px $primaryBlue;
    background-color: $primaryBlue;
}

.sendbird-reaction-button--selected:hover {
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light
    .sendbird-user-message--outgoing:hover
    .sendbird-user-message__text-balloon {
    background-color: $primaryBlue;
}

.sendbird-theme--light
    .sendbird-user-message--outgoing
    .sendbird-label--color-onbackground-1 {
    color: white;
}

.sendbird-theme--light
    .sendbird-user-message--outgoing:hover
    .sendbird-user-message__text-balloon
    .sendbird-user-message__text-balloon__inner
    .sendbird-user-message__text-balloon__inner__emoji-reactions {
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light
    .sendbird-user-message--outgoing
    .sendbird-user-message__text-balloon {
    background-color: $primaryBlue;
}

.sendbird-theme--light
    .sendbird-user-message--outgoing
    .sendbird-user-message__text-balloon
    .sendbird-user-message__text-balloon__inner
    .sendbird-user-message__text-balloon__inner__emoji-reactions {
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light .sendbird-reaction-badge:hover {
    border: solid 1px $primaryBlue;
}

.sendbird-reaction-badge--is-add:hover {
    cursor: pointer;
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light .sendbird-reaction-badge--selected {
    background-color: $primaryBlue;
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light .sendbird-reaction-badge--selected:hover {
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light
    .sendbird-file-message__outgoing:hover
    .sendbird-file-message__tooltip {
    background-color: $primaryBlue;
}

.sendbird-theme--light
    .sendbird-file-message__outgoing:hover
    .sendbird-file-message__tooltip
    .sendbird-file-message__tooltip__emoji-reactions {
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light
    .sendbird-file-message__outgoing
    .sendbird-file-message__tooltip {
    background-color: $primaryBlue;
}

.sendbird-theme--light
    .sendbird-file-message__outgoing
    .sendbird-file-message__tooltip
    .sendbird-file-message__tooltip__emoji-reactions {
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light .sendbird-color--primary {
    color: $primaryBlue;
}

.sendbird-theme--light .sendbird-color--primary--background-color {
    background-color: $primaryBlue;
}

.sendbird-theme--light
    .sendbird-message-input
    .sendbird-message-input--textarea {
    caret-color: $primaryBlue;
}

.sendbird-theme--light
    .sendbird-message-input
    .sendbird-message-input--textarea:focus {
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light
    .sendbird-message-input
    .sendbird-message-input--textarea:active {
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light
    .sendbird-outgoing-unknown-message:hover
    .sendbird-outgoing-unknown-message__body__text-balloon {
    background-color: $primaryBlue;
}

.sendbird-theme--light .sendbird-outgoing-unknown-message__body__text-balloon {
    background-color: $primaryBlue;
}

.sendbird-theme--light
    .sendbird-outgoing-og-message:hover
    .sendbird-outgoing-og-message__text-balloon {
    background-color: $primaryBlue;
}

.sendbird-theme--light .sendbird-outgoing-og-message__text-balloon {
    background-color: $primaryBlue;
}

.sendbird-theme--light .sendbird-notification {
    background-color: $primaryBlue;
}

.sendbird-theme--light .sendbird-notification:hover {
    background-color: $primaryBlue;
}

.sendbird-theme--light .sendbird-input .sendbird-input--input {
    caret-color: $primaryBlue;
}

.sendbird-theme--light .sendbird-input .sendbird-input--input:focus {
    border: solid 2px $primaryBlue;
}

.sendbird-theme--light .sendbird-input .sendbird-input--input:active {
    border: solid 1px $primaryBlue;
}

.sendbird-theme--light .sendbird-iconbutton:hover {
    background-color: white;
}

.sendbird-theme--light .sendbird-iconbutton--pressed {
    background-color: $lightTeal;
}

.sendbird-theme--light .sendbird-message-input__edit {
    background-color: $lightTeal;
    border: 1px solid $mediumTeal;
    border-radius: 6px;
}
